body {
  margin: 0;
  padding: 20;
  font-family: sans-serif;
  background-color: black;
}

.main-app {
  text-align: center;
  margin: 100px;
  color: white;
}


.cta-button {
  padding: 15px;
  border: none;
  border-radius: 12px;
  min-width: 250px;
  color: red;
  font-size: 18px;
  cursor: pointer;
}

.connect-wallet-button {
  background: rgb(32, 129, 226);
}

.claim-token-button {
  background: orange;
}