.main-app {
  text-align: center;
  margin: 100px;
}

.cta-button {
  padding: 15px;
  border: none;
  border-radius: 12px;
  min-width: 250px;
  color: white;
  font-size: 18px;
  cursor: pointer;
}


.connect-wallet-button {
  background: rgb(32, 129, 226);
}

h1 {
color: white;
}

p {
  color: white;
}

.claim-token-button {
  background: #D8B2B4;
}

.spread-video > video {
  width: 100%;
}
